import PropTypes from 'prop-types';
/**
 * @description Biggest title in the app
 */

export default function H1Title({children,className=''}){
    return(
    <h1 className={`${className} text-2xl text-black font-bold`}>{children}</h1>
    )
}

H1Title.propTypes ={
    /** Passes the text to the component */
    children:PropTypes.string,
    /** Used to edit the styles using Tailwind */
    className:PropTypes.string,
}

H1Title.defaultProps ={
    className:'',
}