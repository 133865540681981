import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiEndpoints } from '../../data/api_endpoints'

const initialState = {
    UserSession: {}
}

export const AuthenticateLogin = createAsyncThunk(
    'authenthicate',
    async (values) => {
        const resp = await fetch(
            apiEndpoints.LOGIN_AUTHENTICATION,
            {
                method: 'POST',
                cache: 'no-cache',
                body: JSON.stringify(values)
            })
        return resp.json()
    }
)

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        removeUserSession: (state) => {
            state.UserSession = {}
        },
        addUserSession: (state, { payload }) => {
            state.UserSession = { 'app_user': payload.app_user, 'token': payload.token }
        },

    },
    extraReducers: {
        [AuthenticateLogin.pending]:(state,action)=>{
        },
        [AuthenticateLogin.fulfilled]:(state,{ payload })=>{
            const response = payload
            if (response==='success'){
                state.UserSession = {'token':'new_token'}
            }else if (response==='failure - wrong credentials'){
                alert('Wrong credentials!')
            }
        },
        [AuthenticateLogin.rejected]:(state,action)=>{
            alert('There was an error with the backend connection')
        },
    }
    })

export const { removeUserSession, addUserSession } = loginSlice.actions

export default loginSlice.reducer