import PRegular from '../../atoms/Text/PRegular'
import { ErrorMessage, Field } from "formik";

export default function FormCheckBoxGroup({ label, className, dbName, values ,disabled=false}) {
    return (
        <div className={`${className}`}>
            <PRegular>{label}</PRegular>
            <div className='h-8 mt-2 flex justify-between w-full'>
                {values.map(
                    (value,i) =>
                        <label key={i}>
                            <Field
                                key={i}
                                name={dbName}
                                type='checkbox'
                                value={value}
                                label={value}
                                className='mr-2'
                                disabled={disabled? true:false}
                            />
                            {value}
                        </label>

                )}
            </div>
            <PRegular className='text-red-400'><ErrorMessage name={dbName} /></PRegular>
        </div>
    )
}