import { ReactComponent as LogoMain } from "../atoms/Logo/LogoMain.svg"
import PRegular from "../atoms/Text/PRegular"

export default function Navbar({ title, let_type }) {
    return (
        <div className='w-full'>
            <div className='w-full flex flex-between p-4 border-b'>
                <div className='hidden sm:flex w-4/12 items-center '>
                    <a href='/'> <PRegular >Marketing Channels</PRegular></a>
                    <a href='/drive'> <PRegular className='ml-4'> Drive</PRegular> </a>
                </div>
                <div className='flex w-4/12 justify-center' ><LogoMain /></div>
                <div className='flex w-4/12'></div>
            </div>
            <div className='flex border-b'>
                {//<DropDown text='Flat Code'/>
                }
            </div>
        </div>
    )
}