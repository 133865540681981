import CardDrive from "../molecules/Card/CardDrive";

export default function CardContainerDrive({data}) {
    return(
        <div className='grid m-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 '>
            {data.map((item,i) => 
                <CardDrive key={i} title={item.title} status={item.status} let_type={item.let_type}/>
            )}
        </div>
    )
}