import DotLoader from "react-spinners/ScaleLoader";
import PRegular from "../../atoms/Text/PRegular";

export default function ModalLoading({ showLoading=false }) {
    return (
        <div className=''>
            {
                showLoading &&
                <div className='h-screen w-screen absolute bg-yellow-300 bg-opacity-100 flex flex-col justify-center items-center top-0'>
                    <PRegular className=''>Loading...</PRegular>
                    <DotLoader color='black' height={60} width={11} />
                </div>
            }
        </div>
    )
}