//3rd parties
import { useState } from "react";
import PropTypes from 'prop-types';

//get data
import H1Title from "../../atoms/Text/H1Title";
import H3Blue from "../../atoms/Text/H3Blue";
import { ReactComponent as IconMenuDots } from "../../atoms/Icon/IconMenuDots.svg"
import DropDownCardMenu from "../Dropdown/DropDownCardMenu";
import CardIcon from "./CardIcon";


/**
 * @description Card with the information about the app. The component is responsive to it's container.
 * 
 * @param {*} param0 
 * @returns 
 */

export default function Card({ title, let_type,marketing_channel }) {
    const [showMenu,setShowMenu] = useState(false)
    return (
        <div className='border w-full rounded p-4 relative'>
            <H3Blue>{let_type}</H3Blue>
            <div className='flex justify-between mb-2'>
                <H1Title>{title}</H1Title>
                <IconMenuDots className='cursor-pointer'  onClick={()=>setShowMenu(!showMenu)} />
            </div>
            
            <div className='flex w-40 justify-between mt-2'>
                
                <CardIcon channel = 'rightmove' href={marketing_channel.rightmove} />
                <CardIcon channel = 'zoopla' href={marketing_channel.zoopla} />
                <CardIcon channel = 'boomin' href={marketing_channel.boomin} />
                <CardIcon channel = 'onthemarket' href={marketing_channel.onthemarket} />
            </div>
            {showMenu && <DropDownCardMenu className='absolute top-12 right-6' flat_code={title} let_type={let_type} marketing_channel={marketing_channel} setShowMenu={setShowMenu}/>}

        </div>
    )
}

Card.propTypes ={
    /** Title of the component */
    title:PropTypes.string,
    /** Subtitle */
    let_type:PropTypes.string,
    /** Data with the links to the channels */
}

Card.defaultProps ={
    let_type:'Long Let',
}