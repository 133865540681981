/**
 *  @description Manages the store with redux persist
 *  @version 1.0.0
 *  @since 13/02/2022
 *  @author ap
 *
 *  @function
 *  @name store
 *  @returns {Component} Returns the store
 * @see https://stackoverflow.com/questions/63761763/how-to-configure-redux-persist-with-redux-toolkit
 */

//interntal
import marketingChannelSlice from './slice/marketingChannelSlice'
import driveSlice from './slice/driveSlice'
import loginSlice from './slice/loginSlice'

//3rd parties
import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'

const reducers = combineReducers({
    marketingChannel: marketingChannelSlice,
    drive: driveSlice,
    login: loginSlice,        
   });
   
   const persistConfig = {
       key: 'root',
       storage:storage,
       whitelist:['login']
   };
   
   const persistedReducer = persistReducer(persistConfig, reducers);
   

export const store = configureStore({
    reducer: persistedReducer,
})