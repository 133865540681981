import H3Dark from "../Text/H3Dark";
import PropTypes from 'prop-types';

/**
 * @description: Primary Button for user interaction
 * @author: AP
 * @since: 13/02/2022
 */

export default function ButtonPrimary({ children, className, size, colour, clickFunction, type }) {
    let colourClass = 'bg-yellow-300 hover:bg-yellow-400'
    if (colour === 'white') {
        colourClass = 'bg-white hover:bg-gray-100'
    } else if (colour === 'gray') {
        colourClass = 'bg-gray-300 hover:bg-gray-400'
    } else if (colour === 'red') {
        colourClass = 'bg-red-400 hover:bg-red-500'
    }

    let sizeClass = 'w-56'
    if (size === 'medium') {
        sizeClass = 'w-40'
    } else if (size === 'large') {
        sizeClass = 'w-56'
    }

    return (
        <button className={`${className} h-12 rounded ${colourClass} ${sizeClass}`} onClick={clickFunction} type={type}>
            <H3Dark className='w-full'>{children}</H3Dark>
        </button>
    )
}

ButtonPrimary.propTypes = {
    /** Passes the text to the component */
    children: PropTypes.string,
    /** Used to edit the styles using Tailwind */
    className: PropTypes.string,
    /** Manges the size */
    size: PropTypes.oneOf(['medium', 'large']),
    /** Manges the colour */
    colour: PropTypes.oneOf(['white', 'yellow', 'gray', 'red']),
    /** Function executed on click */
    /** Type of the button */
    type: PropTypes.string,
}

ButtonPrimary.defaultProps = {
    className: '',
    size: 'large',
    colour: 'yellow',
    type: '',
};