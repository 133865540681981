import PRegular from "../Text/PRegular"
import PropTypes from 'prop-types';
import {ReactComponent as IconDelete} from "../Icon/IconDelete.svg"

/**
 * @description Shows an Icon and a text on the Navigation Menu
 * @author APM
 * @param {*} 
 * @returns 
 */

export default function DropDownMenuElement({text,icon,clickFunction}) {
return(
    <div className='flex w-32 items-center justify-start p-2 hover:bg-gray-200 cursor-pointer w-full' onClick={clickFunction}>
        <div className="w-6">{icon}</div>
        <PRegular className='ml-3'>{text}</PRegular>
    </div>
)
}

DropDownMenuElement.propTypes ={
    /** Text in the element */
    text:PropTypes.string,
    /** Pass the icon to the element */
    icon:PropTypes.object,
    /** Function that gets executed */
}

DropDownMenuElement.defaultProps ={
    text:'',
    icon:<IconDelete />,
}