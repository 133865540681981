import PRegular from '../../atoms/Text/PRegular'
import { ErrorMessage, Field } from "formik";

export default function FormInputBox({label,className,dbName, type = 'text',disabled=false}){
return(
    <div className={`${className}`}>
        <PRegular>{label}</PRegular>
        <Field
            name={dbName}
            type={type}
            className='w-full h-8 border-b'
            disabled={disabled? true:false}
        />
        <PRegular className='text-red-400'><ErrorMessage name={dbName} /></PRegular>
    </div>
)
}