//3rd party components
import { useState } from "react";
import PropTypes from 'prop-types';

//internal components
import H1Title from "../../atoms/Text/H1Title";
import H3Blue from "../../atoms/Text/H3Blue";
import { ReactComponent as IconMenuDots } from "../../atoms/Icon/IconMenuDots.svg"
import DropDownCardMenuDrive from "../Dropdown/DropDownCardMenuDrive";

/**
 * @description It's the card managed in the internal marketing page
 * @param {*} param0 
 * @returns 
 */

export default function Card({title, status}) {
    const [showMenu,setShowMenu] = useState(false)

    const returnMessageBasedOnStatus = (status) => {
        switch (status) {
            case 'both':
                return (
                    <div>
                        <H3Blue>Host form Uploaded</H3Blue>   
                        <H3Blue>Appliance form Uploaded</H3Blue>   
                    </div>
                )
            case 'appliance':
                return (
                    <H3Blue>Appliance form Uploaded</H3Blue>
                )
            case 'host':
                return (
                    <H3Blue>Host form Uploaded</H3Blue> 
                )
            default:
                break;
        }
    }

    return (
        <div className='border w-full rounded p-4 relative'>
            <div className='flex justify-between mb-2'>
                <H1Title>{title}</H1Title>
                <IconMenuDots className='cursor-pointer'  onClick={()=>setShowMenu(!showMenu)} />
            </div>
            {returnMessageBasedOnStatus(status)}
            <div className='flex w-40 justify-between mt-2'>
            </div>
            {showMenu && <DropDownCardMenuDrive className='absolute top-12 right-6' setShowMenu={setShowMenu} property_name={title} status={status}/>}

        </div>
    )
}

Card.propTypes ={
    /** Title of the component */
    title:PropTypes.string,
}

Card.defaultProps ={
    let_type:'Long Let',
}