import PropTypes from 'prop-types';

/**
 * @description Blue title in the app
 */

export default function H3Dark({children,className}){
    return(
    <h3 className={`${className} text-base text-dark-500 uppercase`}>{children}</h3>
    )
}

H3Dark.propTypes ={
    /** Passes the text to the component */
    children:PropTypes.string,
    /** Used to edit the styles using Tailwind */
    className:PropTypes.string,
}

H3Dark.defaultProps ={
    className:'',
}