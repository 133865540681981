import Card from "../molecules/Card/Card";

export default function CardContainer({data}) {
    return(
        <div className='grid m-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 '>
            {data.map((item,i) => 
                <Card key={i} title={item.title} let_type={item.let_type} marketing_channel={item.marketing_channel}/>
            )}
        </div>
    )
}