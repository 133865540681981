import React, {useEffect} from 'react';
import PRegular from '../UI/atoms/Text/PRegular';
import CardContainerDrive from '../UI/organisms/CardContainerDrive';
import Navbar from '../UI/organisms/NavBar';
import ModalLoading from '../UI/organisms/Modal/ModalLoading';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { setShowDriveModal } from '../businessLogic/redux/slice/driveSlice';
import { GetAllFlat,PostFlat, PostFlatAppliances, PostFlatHost } from '../businessLogic/redux/slice/driveSlice';

import ModalDrive from '../UI/organisms/Modal/ModalDrive';

export default function Home(props) {
    //redux related
    const showDriveModal = useSelector((state) => state.drive.showDriveModal)
    const showModalLoading =  useSelector((state) => state.drive.showModalLoading)
    const AllFlat = useSelector((state) => state.drive.AllFlat)
    const initialValues = useSelector((state) => state.drive.initialValues)
    const dataUpdated = useSelector((state) => state.drive.dataUpdated)
    const form = useSelector((state) => state.drive.form)
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(GetAllFlat())
    }, [dispatch,dataUpdated])
    
    const handleForm = (form, values) => {
        console.log(form)
        switch (form) {
            case 'hostAmenities':
                return dispatch(PostFlat(values))   
            case 'appliances':
                return dispatch(PostFlatAppliances(values))    
            case 'host':
                return dispatch(PostFlatHost(values))     
            default:
                break;
        }
    }

    return (
        <div>
            <Navbar />
            <PRegular className='mx-4 my-8'>We found {AllFlat.length} properties in the <a target="_blank" href='https://docs.google.com/spreadsheets/d/1NvxhLcNlk0FPwOlOoxqIUWKIxRSYRnf8hHqmtY9FJLo/edit#gid=900395038' rel="noreferrer">HOST ONBOARDING FORM</a> that can be uploaded into Drive.</PRegular>
            <CardContainerDrive data={AllFlat} />
            {showDriveModal &&
                <ModalDrive
                    cancelClickFunction={() => dispatch(setShowDriveModal())}
                    initialValues={initialValues}
                    submitAction={(values) => {handleForm(form, values)}}
                />}
            <ModalLoading showLoading={showModalLoading}/>
        </div>
    )

}