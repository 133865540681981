import { Form, Formik } from "formik";
import ButtonPrimary from "../../atoms/Button/ButtonPrimary";
import H1Title from "../../atoms/Text/H1Title";
import FormInputBox from "../../molecules/Form/FormInputBox";
import * as Yup from 'yup';

export default function ModalDrive({ cancelClickFunction,initialValues,submitAction}) {

    //actions: create, update, block, delete

    return (
        <div className='w-screen h-screen fixed bg-black top-0 bg-opacity-70 flex'>
            <div className='bg-white p-4 m-auto w-96 rounded'>

                <H1Title className='text-opacity-100'>Upload property into Drive</H1Title>
                <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
					property_name: Yup.string().required('A property is required.'),
				})}
                onSubmit={submitAction}
                >
                    <Form >
                        <input hidden label='Action' className='pt-6' name='action' />  
                        <FormInputBox label='Property Name' className='pt-6 pb-6' dbName='property_name' disabled={true}/>

                        <div className='flex flex-row justify-between'>
                            <ButtonPrimary size='medium' colour='white' clickFunction={cancelClickFunction} >CANCEL</ButtonPrimary>
                            <ButtonPrimary size='medium' colour='yellow' type='submit'>UPLOAD</ButtonPrimary>
                        </div>
                    </Form>
                </Formik>
            </div>

        </div>
    )

}