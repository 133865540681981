import React from 'react';
import { Route, Redirect } from 'react-router-dom'

//redux
import { useSelector } from 'react-redux';

//handle private routes
function PrivateRoute({component: Component, ...rest }){
    const token = useSelector((state) => state.login.UserSession.token)
    return(
        <Route
        {...rest}
        render={(props)=> token?
            <Component {...props} /> :
            <Redirect to={{pathname: '/login', state:{from:props.location}}} />
        }
        />
    )
    }

function PublicRoute({component: Component, ...rest }){
    const token = useSelector((state) => state.login.UserSession.token)
        return(
            <Route
            {...rest}
            render={(props)=> !token?
                <Component {...props} /> :
                <Redirect to={{pathname: '/'}} /> 
                
            }
            />
        )
        }

export {PrivateRoute,PublicRoute}