import DropDownMenuElement from "../../atoms/Menu/DropdownMenuElement";

import { ReactComponent as Upload } from "../../atoms/Icon/IconUpload.svg"

//redux
//to confirm this implemntation. The code is cleaner but it's not replicable
import { useDispatch } from 'react-redux';
import { CreateClick } from '../../../businessLogic/redux/slice/driveSlice';

export default function DropDownCardMenuDrive({className, property_name, setShowMenu, status}) {
    const dispatch = useDispatch()

    const returnMessageBasedOnStatus = (status) => {
        switch (status) {
            case 'both':
                return (
                    <DropDownMenuElement text='UPLOAD HOST AND APPLIANCES FORM' icon={<Upload/>} clickFunction={()=>{dispatch(CreateClick({property_name, form: 'hostAmenities'}));setShowMenu(false)}}/>
                )
            case 'appliance':
                return (
                    <DropDownMenuElement text='UPLOAD APPLIANCES FORM' icon={<Upload/>} clickFunction={()=>{dispatch(CreateClick({property_name, form: 'appliances'}));setShowMenu(false)}}/>
                )
            case 'host':
                return (
                    <DropDownMenuElement text='UPLOAD HOST FORM' icon={<Upload/>} clickFunction={()=>{dispatch(CreateClick({property_name, form: 'host'}));setShowMenu(false)}}/>            
                )
            default:
                break;
        }
    }

    return (
        <div className={`${className} border rounded bg-white w-4/5	` }>
            {returnMessageBasedOnStatus(status)}
        </div>
    )
}