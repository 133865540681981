import { Form, Formik } from "formik";
import ButtonPrimary from "../../atoms/Button/ButtonPrimary";
import H1Title from "../../atoms/Text/H1Title";
import FormInputBox from "../../molecules/Form/FormInputBox";
import * as Yup from 'yup';

export default function ModalLogin({submitAction}) {
    return (
        <div className='w-screen h-screen absolute bg-black top-0 bg-opacity-70 flex'>
            <div className='bg-white p-4 m-auto w-96 rounded'>

                <H1Title className='text-opacity-100'>Login</H1Title>
                <Formik
                initialValues={{'email':'','password':''}}
                validationSchema={Yup.object({
					email: Yup.string().required('An email is required'),
				})}
                onSubmit={
					submitAction}
                >
                    <Form >
                        <input hidden label='Action' className='pt-6' name='action' />  
                        <FormInputBox label='Email' className='pt-6 pb-6' dbName='email' />
                        <FormInputBox label='Password' className='pt-6 pb-6' dbName='password' type='password'/>
                        <div className='flex flex-row justify-center'>
                            <ButtonPrimary size='medium' colour='yellow' type='submit'>LOGIN</ButtonPrimary>
                        </div>
                    </Form>
                </Formik>
            </div>

        </div>
    )

}