import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiEndpoints } from '../../data/api_endpoints'

const initialState = {
    showDriveModal: false,
    showModalLoading: true,
    AllFlat: [{ 'title': 'ABS' }, { 'title': 'IFJ' }, { 'title': 'ABS' }, { 'title': 'IFJ' }],
    initialValues: { property_name: '' },
    dataUpdated: 0,
    form: ''
}

//API connections
export const GetAllFlat = createAsyncThunk(
    'drive/getAll',
    async () => {
        const resp = await fetch(apiEndpoints.DRIVE_GETALL)
        return resp.json()
    })

export const PostFlat = createAsyncThunk(
    'drive/Post',
    async (values) => {
        const resp = await fetch(
            apiEndpoints.DRIVE_POST,
            {
                method: 'POST',
                cache: 'no-cache',
                body: JSON.stringify(values)
            })
        return resp.json()            
    })

export const PostFlatAppliances = createAsyncThunk(
    'drive/Post',
    async (values) => {
        const resp = await fetch(
            `${apiEndpoints.DRIVE_POST_APPLIANCES}/${values.property_name}`,
            {
                method: 'POST',
                cache: 'no-cache',
                body: JSON.stringify(values)
            })
        return resp.json()
    })

export const PostFlatHost = createAsyncThunk(
    'drive/Post',
    async (values) => {
        const resp = await fetch(
            `${apiEndpoints.DRIVE_POST_HOST}/${values.property_name}`,
            {
                method: 'POST',
                cache: 'no-cache',
                body: JSON.stringify(values)
            })
        return resp.json()
    })   


export const driveSlice = createSlice({
    name: 'marketingChannel',
    initialState,
    reducers: {
        //showUpdateModal
        setShowDriveModal: (state) => { state.showDriveModal = !state.showDriveModal },
        //initialvalues
        CreateClick: (state, { payload }) => {
            state.initialValues = { property_name: payload.property_name };
            state.showDriveModal = !state.showDriveModal;
            state.form = payload.form
        }
        
    },
    extraReducers: {
        //get All flat
        [GetAllFlat.fulfilled]: (state, { payload }) => {
            state.AllFlat = payload;
            state.showModalLoading = false;
            
        },
        [GetAllFlat.pending]: (state, action) => {
            state.showModalLoading = true;
        },
        [GetAllFlat.rejected]: (state, action) => {
            alert('There was an error with the backend connection');
            state.showModalLoading = false;
        },
        //post flat
        [PostFlat.fulfilled]: (state, { payload }) => {
            if (payload === 'Flat not found on Drive') {
                alert('The flat was not found on Drive.')
            }else if(payload.error){
                alert(payload.error)
            } else {
                alert('Succesfully uploaded');

            }
            state.showModalLoading = false;
            state.showDriveModal = false;
            state.dataUpdated += 1;

        },
        [PostFlat.pending]: (state, action) => {
            state.showModalLoading = true;

        },
        [PostFlat.rejected]: (state, action) => {
            alert('There was an error with the backend connection');
            state.showModalLoading = false;
        },
        //post flat appliances
        [PostFlatAppliances.fulfilled]: (state, { payload }) => {
            if (payload === 'Flat not found on Drive') {
                alert('The flat was not found on Drive.')
            }else if(payload.error){
                alert(payload.error)
            } else {
                alert('Succesfully uploaded');

            }
            state.showModalLoading = false;
            state.showDriveModal = false;
            state.dataUpdated += 1;

        },
        [PostFlatAppliances.pending]: (state, action) => {
            state.showModalLoading = true;

        },
        [PostFlatAppliances.rejected]: (state,  { payload }) => {
            alert('There was an error with the backend connection' + payload);
            state.showModalLoading = false;
        },
        //post flat host
        [PostFlatHost.fulfilled]: (state, { payload }) => {
            if (payload === 'Flat not found on Drive') {
                alert('The flat was not found on Drive.')
            }else if(payload.error){
                alert(payload.error)
            } else {
                alert('Succesfully uploaded');

            }
            state.showModalLoading = false;
            state.showDriveModal = false;
            state.dataUpdated += 1;

        },
        [PostFlatHost.pending]: (state, action) => {
            state.showModalLoading = true;

        },
        [PostFlatHost.rejected]: (state, { payload }) => {
            alert('There was an error with the backend connection' + payload);
            state.showModalLoading = false;
        },
    }
})

export const { setShowDriveModal, CreateClick } = driveSlice.actions

export default driveSlice.reducer