/** 
 *  @description This is a functional component for redux, redux persist configuration and rooter called
 *  @version 1.0.0
 *  @since 13/02/2022
 *  @author ap
 *
 *  @function
 *  @name App
 *  @returns {Component} Returns the configuration and router
 **/

//in the app
import './App.css';
import AppRouter from './businessLogic/router/AppRouter';

//redux
import { store } from './businessLogic/redux/store';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

let persistor = persistStore(store);


function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppRouter />
      </PersistGate>
    </Provider>
  );
}

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store
}

export default App;
