import React, { useEffect } from 'react';
import ButtonPrimary from '../UI/atoms/Button/ButtonPrimary';
import PRegular from '../UI/atoms/Text/PRegular';
import CardContainer from '../UI/organisms/CardContainer';
import ModalHome from '../UI/organisms/Modal/ModalHome';
import Navbar from '../UI/organisms/NavBar';
import ModalLoading from '../UI/organisms/Modal/ModalLoading';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { setShowUpdateModal,CreateClickHome} from '../businessLogic/redux/slice/marketingChannelSlice';

import {GetAllFlat,PostFlat} from '../businessLogic/redux/slice/marketingChannelSlice';

export default function Home(props) {

    //redux related
    const showUpdateModal = useSelector((state) => state.marketingChannel.showUpdateModal)
    const showModalLoading =  useSelector((state) => state.marketingChannel.showModalLoading)
    const AllFlat = useSelector((state) => state.marketingChannel.AllFlat)
    const initialValues = useSelector((state) => state.marketingChannel.initialValues)
    const modalSettings = useSelector((state) => state.marketingChannel.modalSettings)
    const dataUpdated = useSelector((state) => state.marketingChannel.dataUpdated)
    
    const dispatch = useDispatch()

	useEffect(() => {
			dispatch(GetAllFlat());
	},[dispatch,dataUpdated])
    return (
        <div>
            <Navbar />
            <PRegular className='mx-4 my-8'>We found {AllFlat.length.toString()} properties in Marketing Channels.</PRegular>
            <CardContainer data={AllFlat} />
            <ButtonPrimary className='fixed bottom-4 right-4 x-10' colour='yellow' clickFunction={()=>dispatch(CreateClickHome())} >New Property</ButtonPrimary>
            {showUpdateModal &&
                <ModalHome
                    cancelClickFunction={()=>dispatch(setShowUpdateModal())}
                    initialValues={initialValues}
                    modalSettings={modalSettings}
                    submitAction={(values) => dispatch(PostFlat(values))}
                />}
            <ModalLoading showLoading={showModalLoading}/>
            
        </div>
    )

}