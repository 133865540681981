import PropTypes from 'prop-types';

/**
 * @description Dark text in the app
 */

export default function PRegular({ children, className }) {
    return (
        <h3 className={`${className} text-base text-dark-500`}>{children}</h3>
    )
}

PRegular.propTypes = {
    /** Used to edit the styles using Tailwind */
    className: PropTypes.string,
}

PRegular.defaultProps = {
    className: '',
}