import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiEndpoints } from '../../data/api_endpoints'

const initialState = {
    showUpdateModal: false,
    showModalLoading: true,
    AllFlat: [{ 'title': 'Not working', 'let_type': 'Mid let', 'marketing_channel': { 'rightmove': '123', 'zoopla': '123', 'boomin': '321' } },],
    initialValues: { action: 'create', property_name: '', let_type: '', channel: ['rightmove'] },
    modalSettings: { title: 'Add a new Property', buttonColor: 'yellow', FormInputBoxDisabled: false, FormCheckBoxesDisabled: false, FormCheckBoxGroupDisabled: false },
    dataUpdated: 0,
}

//API connections
export const GetAllFlat = createAsyncThunk(
    'marketingChannel/getAll',
    async () => {
        const resp = await fetch(apiEndpoints.MARKETING_GETALL)
        return resp.json()
    })

export const PostFlat = createAsyncThunk(
    'marketingChannel/Post',
    async (values) => {
        const resp = await fetch(
            apiEndpoints.MARKETING_POST,
            {
                method: 'POST',
                cache: 'no-cache',
                body: JSON.stringify(values)
            })
            
        return resp.json()
    })

//REDUX
export const marketingChannelSlice = createSlice({
    name: 'marketingChannel',
    initialState,
    reducers: {
        //showUpdateModal
        setShowUpdateModal: (state) => { state.showUpdateModal = !state.showUpdateModal },
        //initialvalues
        actionClick: (state, { payload }) => {
            state.initialValues = { action: payload.action, property_name: payload.flat_code, let_type: payload.let_type, channel: payload.channel };
            state.showUpdateModal = !state.showUpdateModal;
            if (payload.action === 'update') {
                state.modalSettings = { title: 'Update Property ' + payload.flat_code, buttonColor: 'yellow', FormInputBoxDisabled: true, FormCheckBoxesDisabled: true, FormCheckBoxGroupDisabled: false }
            } else if (payload.action === 'delete') {
                state.modalSettings = { title: 'Delete Property ' + payload.flat_code, buttonColor: 'red', FormInputBoxDisabled: true, FormCheckBoxesDisabled: true, FormCheckBoxGroupDisabled: true }
            } else if (payload.action === 'block') {
                state.modalSettings = { title: 'Block Property ' + payload.flat_code, buttonColor: 'gray', FormInputBoxDisabled: true, FormCheckBoxesDisabled: true, FormCheckBoxGroupDisabled: false }
            }
        },
        CreateClickHome: (state) => {
            state.initialValues = { action: 'create', property_name: '', let_type: '', channel: ['rightmove'] };
            state.showUpdateModal = !state.showUpdateModal;
            state.modalSettings = { title: 'Add a new Property', buttonColor: 'yellow', FormInputBoxDisabled: false, FormCheckBoxesDisabled: false, FormCheckBoxGroupDisabled: false }
        },
    },
    extraReducers: {
        //get All flat
        [GetAllFlat.fulfilled]: (state, { payload }) => {
            state.AllFlat = payload;
            state.showModalLoading = false;
        },
        [GetAllFlat.pending]: (state, action) => {
            state.showModalLoading = true;
        },
        [GetAllFlat.rejected]: (state, action) => {
            alert('There was an error with the backend connection');
            state.showModalLoading = false;
        },
        //post flat
        [PostFlat.fulfilled]: (state, { payload }) => {
            const response = payload
            if (response.hasOwnProperty("error")){
                alert(response['error'])
            }else{
                alert("Flat succesfully uploaded");
                
            }
            state.showUpdateModal= false;
            state.showModalLoading = false;
            state.dataUpdated += 1;

        },
        [PostFlat.pending]: (state, action) => {
            state.showModalLoading = true;
            console.log('pending')
        },
        [PostFlat.rejected]: (state, action) => {
            alert('There was an error with the backend connection');
            state.showModalLoading = false;
        },
    }
})


export const { setShowUpdateModal, actionClick, CreateClickHome } = marketingChannelSlice.actions

export default marketingChannelSlice.reducer