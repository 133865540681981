//in the app
import Home from '../../pages/Home';
import Drive from '../../pages/Drive';
import Login from '../../pages/Login';
import { PrivateRoute, PublicRoute } from '../../businessLogic/utils/LoginUtils'

//3rd parties
import {BrowserRouter, Switch } from 'react-router-dom'

export default function AppRouter() {

    return (
        <BrowserRouter>
            <Switch>
                <PublicRoute exact path="/login" component={Login} />
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/drive" component={Drive} />
            </Switch>
        </BrowserRouter>
    )
}