import { Form, Formik } from "formik";
import ButtonPrimary from "../../atoms/Button/ButtonPrimary";
import H1Title from "../../atoms/Text/H1Title";
import FormInputBox from "../../molecules/Form/FormInputBox";
import * as Yup from 'yup';
import FormCheckBoxes from "../../molecules/Form/FormCheckBoxes";
import FormCheckBoxGroup from "../../molecules/Form/FormCheckBoxGroup";

export default function ModalHome({ cancelClickFunction,initialValues,modalSettings,submitAction}) {

    //actions: create, update, block, delete

    return (
        <div className='w-screen h-screen bg-black top-0 bg-opacity-70 flex fixed'>
            <div className='bg-white p-4 m-auto w-96 rounded'>

                <H1Title className='text-opacity-100'>{modalSettings.title}</H1Title>
                <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
					property_name: Yup.string().required('A property is required.'),
                    let_type: Yup.string().required('A Let Type is required. 213'),
                    channel: Yup.array().min(1,'At least 1 channel is required').required('At least 1 element is required'),
				})}
                onSubmit={submitAction}
                >
                    <Form >
                        <input hidden label='Action' className='pt-6' name='action' />  
                        <FormInputBox label='Property Name' className='pt-6' dbName='property_name' disabled={modalSettings.FormInputBoxDisabled}/>
                        <FormCheckBoxes label='Let Type' className='pt-6' dbName='let_type' values={['Long let','Mid let']} disabled={modalSettings.FormCheckBoxesDisabled}/>
                        <FormCheckBoxGroup label='Marketing Channels' className='pt-2 pb-2' dbName='channel' values={['rightmove','zoopla','boomin','onthemarket']} disabled={modalSettings.FormCheckBoxGroupDisabled}/>

                        <div className='flex flex-row justify-between'>
                            <ButtonPrimary size='medium' colour='white' clickFunction={cancelClickFunction} >CANCEL</ButtonPrimary>
                            <ButtonPrimary size='medium' colour={modalSettings.buttonColor} type='submit'>{initialValues.action}</ButtonPrimary>
                        </div>
                    </Form>
                </Formik>
            </div>

        </div>
    )

}