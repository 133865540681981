import DropDownMenuElement from "../../atoms/Menu/DropdownMenuElement";

import { ReactComponent as Upload } from "../../atoms/Icon/IconUpload.svg"
import { ReactComponent as Block } from "../../atoms/Icon/IconBlock.svg"

//redux
//to confirm this implemntation. The code is cleaner but it's not replicable
import { useDispatch } from 'react-redux';
import { actionClick } from '../../../businessLogic/redux/slice/marketingChannelSlice';

export default function DropDownCardMenu({className='',flat_code,let_type,marketing_channel,setShowMenu}) {
    const dispatch = useDispatch()
    const channel = Object.keys(marketing_channel)

    return (
        <div className={`${className} border rounded bg-white`}>
            <DropDownMenuElement text='Update' icon={<Upload/>} clickFunction={()=>{dispatch(actionClick({action:'update',flat_code,let_type,channel}));setShowMenu(false)}} />
            <DropDownMenuElement text='Block' icon={<Block/>} clickFunction={()=>{dispatch(actionClick({action:'block',flat_code,let_type,channel}));setShowMenu(false)}}/>
            <DropDownMenuElement text='Delete' clickFunction={()=>{dispatch(actionClick({action:'delete',flat_code,let_type,channel}));setShowMenu(false)}}/>
        </div>
    )
}