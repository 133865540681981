import ModalLogin from "../UI/organisms/Modal/ModalLogin";

//redux
import { useDispatch } from 'react-redux';
import {AuthenticateLogin} from '../businessLogic/redux/slice/loginSlice';


export default function Login(props){
//redux related
const dispatch = useDispatch()

    return(
        <div>
            <ModalLogin submitAction={(values) => {dispatch(AuthenticateLogin(values))}} />
        </div>
    )
}