//3rd parties
import PropTypes from 'prop-types';

//get data
import { ReactComponent as FaviconBoomin } from "../../atoms/Favicon/FaviconBoomin.svg"
import { ReactComponent as FaviconZoopla } from "../../atoms/Favicon/FaviconZoopla.svg"
import { ReactComponent as FaviconRightmove } from "../../atoms/Favicon/FaviconRightmove.svg"
import { ReactComponent as FaviconOnTheMarket } from "../../atoms/Favicon/FaviconOnTheMarket.svg"

/**
 * @description Manages the icons. 
 * 
 * @param {*} param0 
 * 
 * @returns 
 */

export default function CardIcon({ channel, href }) {
    let icon = ''
    if (channel === 'rightmove') {
        icon = <FaviconRightmove />

    } else if (channel === 'zoopla') {
        icon = <FaviconZoopla />

    } else if (channel === 'boomin') {
        icon = <FaviconBoomin />

    } else if (channel === 'onthemarket') {
        icon = <FaviconOnTheMarket />
    }
    return (
        <a href={href} target="_blank" rel="noreferrer" className={`cursor-pointer ${!href && "opacity-10 cursor-not-allowed"}`} >
            {icon}
        </a>
    )
}

CardIcon.propTypes ={
    /** Channel componetns */
    channel:PropTypes.oneOf(['rightmove', 'zoopla','boomin','onthemarket']),
    /** Href that creates the redirect. 
     * !! If it's NULL it disables the component
     */
    href:PropTypes.string,
}

CardIcon.defaultProps ={
    channel:'rightmove',
}
